<template>
  <!-- 代理商列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 代理商姓名 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="商品名称">
                    <a-input v-model="queryParam.goodsName" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="供应商名称">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item
                    label="到账时间"
                    :labelCol="{
                      xs: { span: 24 },
                      sm: { span: 8 },
                    }"
                    :wrapperCol="{
                      xs: { span: 24 },
                      sm: { span: 16 },
                    }"
                  >
                    <div style="position: relative">
                      <a-range-picker
                        style="width: 100%"
                        v-model="searchTime"
                        :show-time="{ format: 'HH:mm' }"
                        format="YYYY-MM-DD HH:mm"
                        :placeholder="['开始时间', '结束时间']"
                        @change="onTimePickerChange"
                      />
                    </div>
                  </a-form-item>
                </a-col>
                <!-- 按钮 -->
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button
                    style="margin-left: 8px"
                    @click="() => ((queryParam = {}), (searchTime = []), initTableData())"
                    >重置</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
  </div>
</template>

<script>
import detailForm from './detailForm.vue'
import { orderGoodPage } from '@/api/modular/mallLiving/orderAdmin'

const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    width: '100px',
    dataIndex: 'goodsName',
    align: 'center',
  },
  {
    title: '商品规格',
    width: '130px',
    dataIndex: 'specName',
    align: 'center',
  },
  {
    title: '数量',
    width: '150px',
    dataIndex: 'buyNum',
    align: 'center',
  },
  {
    title: '单价（元）',
    align: 'center',
    width: '150px',
    dataIndex: 'originPrice',
  },
  {
    title: '总价（元）',
    align: 'center',
    width: '150px',
    dataIndex: 'totalPrice',
  },
  {
    title: '供应商',
    width: '150px',
    dataIndex: 'storeName',
    align: 'center',
  },
  {
    title: '供应商手机号',
    width: '150px',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '下单时间',
    dataIndex: 'createTime',
    width: '150px',
    align: 'center',
  },
  {
    title: '到账时间',
    dataIndex: 'arrivalTime',
    width: '150px',
    align: 'center',
  },
  {
    title: '操作',
    width: '130px',
    align: 'center',
    fixed: 'right',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      searchTime: [],
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {
        arrivalStartTime: '',
        arrivalEndTime: '',
      },
      videoTypeList: [], //视频分类数据
      provinceList: [], //省份列表
      cityList: [], //市区列表
    }
  },
  components: {
    detailForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    onTimePickerChange(value, dateString) {
      console.log('Selected Time: ', value)
      console.log('Formatted Selected Time: ', dateString)
      this.queryParam.arrivalStartTime = this.$dayjs(dateString[0]).valueOf()
      this.queryParam.arrivalEndTime = this.$dayjs(dateString[1]).valueOf()
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },

    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: { ...this.queryParam },
      }
      orderGoodPage(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.table-page-search-wrapper {
  margin-bottom: 15px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
